import { memo, useEffect, useState } from 'react';
import {
  Modal,
  Form,
  Button,
  Row,
  message,
  notification,
  Grid,
  Alert,
  Result,
} from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useRouter } from 'next/router';
import posthog from 'posthog-js';
import { useSWRConfig } from 'swr';
import * as R from 'ramda';

import { Transaction } from 'types/opp';
import { ReferralProps } from 'types';

import { GET_REFERRALS_QUERY, UPDATE_ME_QUERY, UPLOAD_QUERY } from 'queries';

import PaymentForm from 'components/Auth/Onboarding/Investor/PaymentMethod';
import { updateUser } from 'components/Profile/Edit/updateCache';

import { createRenewalTransaction } from 'containers/Auth/Onboarding/Investor/utils';
import useUserData from 'hooks/useUserData';
import { flattenEntities } from 'utils/graphql';
import { fetchGetJSON } from 'utils/apiHelpers';
import { trackBrevoEvent } from 'lib/brevo/tracker';

import PaymentInstructions from '../PaymentInstructions';

export interface RenewalPaymentModalProps {
  isOpen: boolean;
  setIsOpen(isOpen: boolean): void;
  paymentType: 'annual' | 'quarterly' | 'annualQuarterly';
}

const RenewalPaymentModal = ({
  isOpen,
  setIsOpen,
  paymentType,
}: RenewalPaymentModalProps) => {
  const screens = Grid.useBreakpoint();
  const [form] = Form.useForm();
  const router = useRouter();

  const { userData } = useUserData();

  const [paymentComplete, setPaymentComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const paymentMethod = Form.useWatch('paymentMethod', form);

  const { mutate: globalMutate } = useSWRConfig();

  const { data: referralData } = useQuery(GET_REFERRALS_QUERY, {
    skip: !userData,
    variables: {
      filters: {
        user: { id: { eq: userData?.id } },
      },
    },
  });
  const referral: ReferralProps = flattenEntities(referralData?.referrals)
    ?.data[0];

  const [upload] = useMutation(UPLOAD_QUERY);
  const [save] = useMutation(UPDATE_ME_QUERY, {
    update: async (cache, { data }) => {
      updateUser(cache, data, userData.id);
    },
  });

  const handleClose = () => {
    if (router.query.action?.includes('payment')) {
      const { action, ...query } = router.query;
      router.replace({ query }, undefined, { shallow: true });
    }
    setIsOpen(false);
  };

  const handlePaymentSuccess = async (
    wasSuccessful: boolean,
    paymentError: string,
    transaction?: Transaction
  ) => {
    if (wasSuccessful) {
      try {
        if (!userData?.OPP?.annualFee?.cloudSchedulerId) {
          const mockAM =
            router.query.mockAM === 'true' &&
            process.env.ACTION_ENVIRONMENT !== 'production';

          await fetchGetJSON(
            `/api/psp/transactions/initiateAnnualMembership?mockAM=${mockAM}`
          );
        }
        if (!userData?.investorDetails?.cloudSchedulerId) {
          const mockQI =
            router.query.mockQI === 'true' &&
            process.env.ACTION_ENVIRONMENT !== 'production';

          await fetchGetJSON(
            `/api/psp/transactions/initiateQuarterlyInvestment?mockQI=${mockQI}`
          );
        }
      } catch (error) {
        Sentry.captureException(error, {
          user: { id: userData?.id },
          tags: {
            action: 'initiate_membership_payments',
          },
        });
        throw error;
      }
      posthog.capture(`dashboard_${paymentType}_renewal_initiated`);

      if (!userData?.investmentTerms) {
        const file = form.getFieldValue([
          'investorDetails',
          'investmentTermsDocument',
        ]);

        if (!file) {
          Sentry.captureException('No file provided for investment terms!', {
            level: 'fatal',
            user: { id: userData?.id },
            tags: {
              action: 'upload_investment_terms',
            },
          });
        } else {
          try {
            await upload({
              variables: {
                refId: userData.id,
                ref: 'plugin::users-permissions.user',
                field: 'investmentTerms',
                file,
              },
            });
          } catch (error) {
            Sentry.captureException(error, {
              user: { id: userData?.id },
              tags: {
                action: 'upload_investment_terms',
              },
            });
          }
        }
      }

      await globalMutate(
        (key) =>
          typeof key === 'string' &&
          key.includes(
            `/api/psp/transactions?filter[merchant]=${userData.OPP.merchantId}`
          ),
        undefined,
        { revalidate: true }
      );

      if (paymentMethod === 'sepa') {
        setPaymentComplete(true);
      }

      if (transaction?.redirect_url) {
        window.location.href = transaction.redirect_url;
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
      console.log('paymentError', paymentError);
      Sentry.captureException(paymentError, {
        user: { id: userData?.id },
        tags: {
          action: `payment_renewal_${paymentType}_error`,
        },
      });
      notification.error({
        message: `Payment error`,
        description:
          'There was an error processing your payment. Please try again later.',
      });
    }
  };

  const handleSubmit = async (values: {
    country: string;
    bank: string;
    paymentMethod: 'pi-single' | 'sepa';
  }) => {
    setLoading(true);

    try {
      await form.validateFields();

      await createRenewalTransaction({
        userData,
        paymentDetails: values,
        paymentType,
        returnUrl: `${process.env.NEXTAUTH_URL}${router.route}?action=payment.${paymentType}`,
        save,
        paymentSuccess: handlePaymentSuccess,
        referral,
        mockPayment: router.query.mockPayment as string,
      });
      posthog.capture('dashboard_membership_payment_submitted');
      trackBrevoEvent(
        'payment_initiated',
        {
          email: userData?.email,
        },
        {
          type: paymentType,
        }
      );
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          formValues: values,
          userId: userData?.id,
        },
        tags: {
          location: 'membership_payment_modal',
          action: 'payment_submission',
        },
      });
      message.error(
        'There was an error processing your payment. Please try again'
      );
      setLoading(false);
    }
  };

  const isMobile = screens.md === false;

  return (
    <Modal
      open={isOpen}
      footer={null}
      width={700}
      title={null}
      onCancel={handleClose}
      closeIcon={null}
      styles={{ body: { padding: isMobile ? '30px' : '40px' } }}
      destroyOnClose
      afterOpenChange={(open) => {
        if (!open) {
          setPaymentComplete(false);
        }
      }}
    >
      {router.query.action?.includes('payment') ? (
        <Result
          status="success"
          title="Thank you for your payment!"
          subTitle="We'll notify you via email as soon as your funds are deposited to your Shuttle account. This usually takes less than an hour, but can take up to 24 hours for some banks."
          extra={[
            <Button type="primary" key="close" onClick={handleClose}>
              Close
            </Button>,
          ]}
        />
      ) : R.isNil(userData?.investorDetails?.quarterlySubscription) ? (
        <Alert
          description="Before proceeding with payment, please set up your Quarterly Investment. This determines how much you'll invest each quarter and will inform the amount of your first payment."
          showIcon
        />
      ) : !paymentComplete && !userData?.OPP?.paymentInstructions ? (
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          {userData?.OPP?.annualFee?.status === 'failed' && (
            <Alert
              description="We were unable to process your annual membership fee. Please ensure you have sufficient funds in your account and try again using another payment method."
              type="error"
              showIcon
              style={{ marginBottom: isMobile ? 15 : 30 }}
            />
          )}
          <PaymentForm form={form} paymentType={paymentType} />

          <Row
            justify="space-between"
            style={{ flexDirection: isMobile ? 'column-reverse' : 'row' }}
          >
            <Button onClick={() => setIsOpen(false)}>Cancel</Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={loading}
              style={{ marginBottom: isMobile ? 15 : 0 }}
            >
              {paymentMethod === 'pi-single'
                ? 'Pay by Bank'
                : 'Get payment instructions'}
            </Button>
          </Row>
        </Form>
      ) : (
        <PaymentInstructions
          setIsOpen={setIsOpen}
          paymentType={`${paymentType}Renewal`}
          setPaymentComplete={setPaymentComplete}
        />
      )}
    </Modal>
  );
};

export default memo(RenewalPaymentModal);

import { memo, useEffect, useState, useRef, useMemo } from 'react';
import {
  Tooltip,
  Grid,
  Checkbox,
  Form,
  Space,
  Typography,
  Select,
  Row,
  Col,
  Alert,
  Skeleton,
} from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import { useQuery } from '@apollo/client';
import { callingCountries, lookup } from 'country-data';

import { PITCHEDIT_FEES } from 'config';

import { GET_REFERRALS_QUERY } from 'queries';
import { GET_BANK_ISSUERS_QUERY } from 'queries';

// import { MandateInput } from 'types/opp';
import { ReferralProps, BankIssuerProps } from 'types';

// import DirectDebit from './DirectDebit';

import useUserData from 'hooks/useUserData';

import { currencyFormatter } from 'utils/strings';
import { flattenEntities } from 'utils/graphql';
import { calculateTotalPrice } from 'containers/Auth/Onboarding/Investor/utils';
import generatePDF from './generatePDF';

import theme from 'styles/theme';
import {
  HeaderRow,
  StyledTitle,
  ContentDivider,
  SummaryRow,
  MobileText,
  StrikethroughTitle,
  ProcessorFeeTitle,
  CheckboxRow,
  StyledLink,
  StyledTitleValue,
  SummaryTitle,
  SummaryValue,
} from './styles';

export interface PaymentMethodProps {
  form: FormInstance;
  paymentType: 'annual' | 'quarterly' | 'annualQuarterly';
}

function PaymentMethod({ form, paymentType }: PaymentMethodProps) {
  const screens = Grid.useBreakpoint();

  const { userData } = useUserData();

  const userCountry = lookup
    .countries({
      alpha2: userData.accountDetails.countryOfResidence,
    })[0]
    ?.alpha3.toUpperCase();

  const [paymentMethod, setPaymentMethod] = useState<'pi-single' | 'sepa'>(
    'pi-single'
  );
  const [selectedCountry, setSelectedCountry] = useState<string>(userCountry);
  const [selectedBank, setSelectedBank] = useState<string>(null);
  const [bankDropdownOpen, setBankDropdownOpen] = useState<boolean>(false);

  // Reference to the bank select component
  const bankSelectRef = useRef(null);

  const { data: bankData, loading: bankDataLoading } = useQuery(
    GET_BANK_ISSUERS_QUERY,
    {
      variables: { country: selectedCountry },
    }
  );
  const issuers: BankIssuerProps[] = useMemo(
    () => flattenEntities(bankData?.bankIssuers)?.data || [],
    [bankData]
  );

  const { data: referralData } = useQuery(GET_REFERRALS_QUERY, {
    skip: !userData,
    variables: {
      filters: {
        user: { id: { eq: userData?.id } },
      },
    },
  });
  const referral: ReferralProps = flattenEntities(referralData?.referrals)
    ?.data[0];

  useEffect(() => {
    const generateFile = async () => {
      const file = await generatePDF(
        `${userData.accountDetails.firstName} ${userData.accountDetails.lastName}`,
        userData.id
      );
      form.setFieldValue(['investorDetails', 'investmentTermsDocument'], file);
    };
    generateFile();
  }, []);

  const isMobile = screens.sm === false;

  const total = calculateTotalPrice(paymentType, userData, referral);

  // Set initial form values for payment method
  useEffect(() => {
    form.setFieldsValue({
      paymentMethod: 'pi-single',
    });
  }, [form]);

  // Get country name from alpha3 code using country-data package
  const getCountryName = (alpha3Code: string): string => {
    const country = lookup.countries({ alpha3: alpha3Code })[0];
    return country ? country.name : alpha3Code;
  };

  // Get unique countries from issuers data
  const countries = useMemo(
    () =>
      Array.from(new Set(issuers.map((issuer) => issuer.country)))
        .map((countryCode) => ({
          value: countryCode,
          label: getCountryName(countryCode),
        }))
        .sort((a, b) => {
          if (a.label < b.label) return -1;
          if (a.label > b.label) return 1;
          return 0;
        }),
    [issuers]
  );

  // Get banks for selected country
  const banksByCountry = selectedCountry
    ? issuers.filter((issuer) => issuer.country === selectedCountry)
    : [];

  return (
    <>
      <HeaderRow>
        <StyledTitle $noMargin={!isMobile} level={4} style={{ marginTop: 0 }}>
          Payment method
        </StyledTitle>
        <Form.Item name="paymentMethod" style={{ margin: 0 }}>
          <Select
            value={paymentMethod}
            onChange={(value) => {
              setPaymentMethod(value);
              form.setFieldsValue({ paymentMethod: value });
            }}
            options={[
              { value: 'pi-single', label: 'Pay by Bank' },
              { value: 'sepa', label: 'SEPA Bank Transfer' },
            ]}
          />
        </Form.Item>
      </HeaderRow>
      <ContentDivider />
      {(paymentType === 'annual' || paymentType === 'annualQuarterly') && (
        <SummaryRow $isMobile={!isMobile}>
          <MobileText $isMobile={!isMobile}>
            Shuttle membership (1 year)
          </MobileText>
          <Space>
            <StrikethroughTitle level={4} type="secondary">
              €500
            </StrikethroughTitle>
            {!referral && (
              <StyledTitleValue
                type={referral ? 'secondary' : null}
                level={4}
                style={{
                  textDecoration: referral ? 'line-through' : 'none',
                }}
              >
                {currencyFormatter(
                  userData.role.name === 'Super Admin' ? 1 : PITCHEDIT_FEES
                )}
              </StyledTitleValue>
            )}
            {referral && (
              <Tooltip
                title="10% referral discount 🎉"
                placement="topRight"
                autoAdjustOverflow={false}
                styles={{
                  body: {
                    fontSize: 14,
                    padding: '5px 10px',
                    minHeight: 26,
                    width: 255,
                  },
                }}
              >
                <StyledTitleValue level={4}>
                  {currencyFormatter(
                    userData.role.name === 'Super Admin'
                      ? 1
                      : PITCHEDIT_FEES - referral.payout
                  )}
                </StyledTitleValue>
              </Tooltip>
            )}
          </Space>
        </SummaryRow>
      )}
      {(paymentType === 'quarterly' || paymentType === 'annualQuarterly') && (
        <SummaryRow $isMobile={!isMobile}>
          <MobileText $isMobile={!isMobile}>Quarterly Investment</MobileText>
          <StyledTitleValue level={4}>
            {currencyFormatter(
              userData.investorDetails.quarterlySubscription || 250
            )}
          </StyledTitleValue>
        </SummaryRow>
      )}
      <SummaryRow $isMobile={!isMobile}>
        <MobileText $isMobile={!isMobile}>Payment processer fee</MobileText>
        <ProcessorFeeTitle level={4}>Free</ProcessorFeeTitle>
      </SummaryRow>
      {paymentMethod === 'pi-single' && (
        <>
          <StyledTitle level={4} $noMargin={false}>
            Bank details
          </StyledTitle>
          <ContentDivider />
          <>
            {selectedCountry && !selectedBank && (
              <Alert
                description={
                  <span style={{ color: '#6B5D81' }}>
                    Don&apos;t see your bank below? Switch to SEPA Bank Transfer
                    using the payment method dropdown above.
                  </span>
                }
                type="info"
                showIcon
                style={{ marginBottom: 16 }}
              />
            )}
          </>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="country"
                label="Country"
                rules={[{ required: true, message: 'Please select a country' }]}
                initialValue={selectedCountry}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="Search to Select"
                  onChange={(value) => {
                    setSelectedCountry(value);
                    setSelectedBank(null);
                    form.setFieldsValue({ bank: undefined });

                    // Open the bank dropdown after a short delay
                    setTimeout(() => {
                      setBankDropdownOpen(true);
                    }, 100);
                  }}
                  filterSort={(a, b) => {
                    const aLabel = a.label;
                    const bLabel = b.label;

                    if (
                      typeof aLabel === 'string' &&
                      typeof bLabel === 'string'
                    ) {
                      return aLabel
                        .toLowerCase()
                        .localeCompare(bLabel.toLowerCase());
                    }

                    // Fallback for non-string values
                    return String(aLabel).localeCompare(String(bLabel));
                  }}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    const optionLabel = option?.label;
                    return (
                      typeof optionLabel === 'string' &&
                      optionLabel.toLowerCase().includes(input.toLowerCase())
                    );
                  }}
                  options={callingCountries.all
                    .filter((country) => country.alpha3) // Filter out countries with no alpha3 code
                    .map((country, index) => ({
                      key: country.alpha3 || `country-${index}`, // Ensure unique key
                      value: country.alpha3,
                      label: country.name,
                    }))
                    // Remove duplicates by alpha3 code
                    .filter(
                      (option, index, self) =>
                        index ===
                        self.findIndex((t) => t.value === option.value)
                    )}
                  optionRender={(option: any) => option.data.label}
                  dropdownRender={(menu) => (
                    <div>
                      <input
                        type="text"
                        autoComplete="country"
                        style={{ display: 'none' }}
                      />
                      {menu}
                    </div>
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="bank"
                label="Bank"
                rules={[{ required: true, message: 'Please select a bank' }]}
              >
                {bankDataLoading ? (
                  <Skeleton.Input
                    active
                    block
                    style={{
                      borderRadius: 100,
                      overflow: 'hidden',
                      display: 'block',
                    }}
                  />
                ) : (
                  <Select
                    placeholder="Select bank"
                    allowClear
                    showSearch
                    optionFilterProp="label"
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    disabled={!selectedCountry}
                    onChange={(value) => {
                      setSelectedBank(value);
                      setBankDropdownOpen(false);
                    }}
                    options={banksByCountry.map((bank) => ({
                      value: bank.bic,
                      label: bank.name,
                    }))}
                    ref={bankSelectRef}
                    open={bankDropdownOpen}
                    onDropdownVisibleChange={(visible) => {
                      setBankDropdownOpen(visible);
                    }}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
      <StyledTitle level={4} $noMargin={false}>
        Summary
      </StyledTitle>
      <ContentDivider />
      <SummaryRow
        $isMobile={!isMobile}
        style={{ marginBottom: userData.investmentTerms ? 40 : 20 }}
      >
        <SummaryTitle level={5}>Total due now</SummaryTitle>
        <SummaryValue level={4}>{currencyFormatter(total)}</SummaryValue>
      </SummaryRow>
      <CheckboxRow $isMobile={isMobile}>
        <Form.Item
          name={['investorDetails', 'oppTerms']}
          valuePropName="checked"
          style={{ margin: '-25px 0 0 0' }}
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error('You must agree to the OPP User Terms')
                    ),
            },
          ]}
        >
          <Checkbox style={{ color: theme.colors.bodyText }}>
            I agree to the OPP{' '}
            <StyledLink
              href="https://6097154.fs1.hubspotusercontent-eu1.net/hubfs/6097154/Terms%20and%20Policies/EN/User%20Terms%20OPP/opp-user-terms-version-04112022.pdf"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'underline' }}
            >
              User Terms
            </StyledLink>{' '}
            (our payments partner)
          </Checkbox>
        </Form.Item>
        {!userData.investmentTerms && (
          <>
            <Form.Item
              name={['investorDetails', 'investmentTerms']}
              style={{ margin: isMobile ? '0 0 15px 0' : '-10px 0 0 0' }}
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(
                            'You must agree to the Shuttle Investment Terms & Conditions'
                          )
                        ),
                },
              ]}
            >
              <Checkbox style={{ color: theme.colors.bodyText }}>
                I agree to the Shuttle{' '}
                <Typography.Link
                  onClick={async (e) => {
                    e.preventDefault();
                    const file = form.getFieldValue([
                      'investorDetails',
                      'investmentTermsDocument',
                    ]);
                    window.open(URL.createObjectURL(file), '_blank');
                  }}
                  style={{ textDecoration: 'underline' }}
                >
                  Investment Terms & Conditions
                </Typography.Link>
              </Checkbox>
            </Form.Item>
            <Form.Item
              name={['investorDetails', 'abilityToBearLoss']}
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(
                            'You must acknowledge you understand the risk of loss of funds'
                          )
                        ),
                },
              ]}
            >
              <Checkbox style={{ color: theme.colors.bodyText }}>
                I acknowledge that any investment I make through this platform
                carries the risk of losing the entirety of the money invested
              </Checkbox>
            </Form.Item>
          </>
        )}
      </CheckboxRow>
    </>
  );
}

export default memo(PaymentMethod);

import { gql } from '@apollo/client';

export const relationshipManagerFragment = gql`
  fragment RelationshipManagerFields on RelationshipManager {
    name
    avatar {
      data {
        attributes {
          url
          width
          height
        }
      }
    }
    email
  }
`;

export const investorFragment = gql`
  fragment InvestorFields on ComponentUserInvestorDetails {
    id
    type
    quarterlySubscription
    quarterlySubscriptionPaused
    netWorth
    cloudSchedulerId
    transactionId
    transactionStatus
  }
`;

export const accountFragment = gql`
  fragment AccountFields on ComponentUserAccountDetails {
    id
    firstName
    lastName
    gender
    phoneNumber
    dateOfBirth
    countryOfResidence
    address
    city
    postcode
    tagline
    bio
  }
`;

export const oppFragment = gql`
  fragment OppFields on ComponentUserOpp {
    id
    merchantId
    mandateId
    bankAccountId
    profileId
    contactId
    annualFee {
      id
      transactionId
      status
      cloudSchedulerId
      cancelledByUser
    }
    paymentInstructions {
      id
      accountName
      iban
      bic
      reference
      expired
    }
    manualPayment {
      id
      transactionId
      status
      accountName
      iban
      bic
      reference
      expired
    }
    kycApproved
  }
`;

export const userDataFragment = gql`
  fragment UserDataFields on UsersPermissionsMe {
    id
    createdAt
    email
    confirmed
    approved
    tourDismissed
    welcomeModalDismissed
    role {
      name
    }
    persona
    avatar {
      url
      width
      height
    }
    referralCode
    verticals {
      id
      name
    }
    skills {
      id
      name
    }
    badges {
      type
      description
      image {
        data {
          attributes {
            url
            width
            height
          }
        }
      }
    }
    twoFactorAuth {
      id
      enabled
      activated
      lastAuthenticated
    }
    investmentTerms {
      url
    }
  }
`;

export const USER_DATA_QUERY = gql`
  ${userDataFragment}
  ${accountFragment}
  ${investorFragment}
  ${oppFragment}
  ${relationshipManagerFragment}
  query {
    me {
      ...UserDataFields
      accountDetails {
        ...AccountFields
      }
      investorDetails {
        ...InvestorFields
      }
      OPP {
        ...OppFields
      }
      relationshipManager {
        ...RelationshipManagerFields
      }
    }
  }
`;

export const FIND_USER_QUERY = gql`
  ${investorFragment}
  query user($id: ID!) {
    user: usersPermissionsUser(id: $id) {
      data {
        id
        attributes {
          email
          confirmed
          avatar {
            data {
              attributes {
                url
              }
            }
          }
          accountDetails {
            firstName
            lastName
            phoneNumber
            dateOfBirth
            countryOfResidence
            gender
          }
          OPP {
            merchantId
            mandateId
            bankAccountId
            profileId
            contactId
            annualFee {
              transactionId
              status
            }
            kycApproved
          }
          investorDetails {
            ...InvestorFields
          }
        }
      }
    }
  }
`;

export const FIND_USERS_QUERY = gql`
  query findUsers(
    $pagination: PaginationArg
    $filters: UsersPermissionsUserFiltersInput
    $sort: [String]
  ) {
    users: usersPermissionsUsers(
      pagination: $pagination
      filters: $filters
      sort: $sort
    ) {
      data {
        id
        attributes {
          confirmed
          persona
          accountDetails {
            firstName
            lastName
          }
          avatar {
            data {
              attributes {
                url
              }
            }
          }
          verticals {
            data {
              attributes {
                name
              }
            }
          }
        }
      }
      meta {
        pagination {
          total
        }
      }
    }
  }
`;

export const UPDATE_ME_QUERY = gql`
  ${userDataFragment}
  ${accountFragment}
  ${investorFragment}
  ${oppFragment}
  ${relationshipManagerFragment}
  mutation updateMe(
    $persona: String
    $accountDetails: ComponentUserAccountDetailsInput
    $twoFactorAuth: ComponentUser2FaInput
    $investorDetails: ComponentUserInvestorDetailsInput
    $OPP: ComponentUserOppInput
  ) {
    updateMe(
      persona: $persona
      accountDetails: $accountDetails
      twoFactorAuth: $twoFactorAuth
      investorDetails: $investorDetails
      OPP: $OPP
    ) {
      ...UserDataFields
      accountDetails {
        ...AccountFields
      }
      investorDetails {
        ...InvestorFields
      }
      OPP {
        ...OppFields
      }
      relationshipManager {
        ...RelationshipManagerFields
      }
    }
  }
`;

export const FORGOT_PASSWORD_QUERY = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email) {
      ok
    }
  }
`;

export const APPROVAL_REQUEST_QUERY = gql`
  query {
    approvalRequest {
      ok
    }
  }
`;

export const RESET_PASSWORD_QUERY = gql`
  mutation resetPassword(
    $code: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    resetPassword(
      code: $code
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      jwt
    }
  }
`;

export const DELETE_ME_QUERY = gql`
  mutation deleteMe($password: String!) {
    deleteMe(password: $password) {
      success
      error
    }
  }
`;

export const ADMIN_STATS_QUERY = gql`
  {
    getAdminStats {
      unpaidAccounts {
        value
        percent
      }
      quarterlyLiquidity
      currentDeposits
      totalInvested
      registeredInvestors
    }
  }
`;

import { gql } from '@apollo/client';

export const GET_BANK_ISSUERS_QUERY = gql`
  query getBankIssuers($country: String) {
    bankIssuers(
      filters: { country: { eq: $country } }
      pagination: { limit: 1000 }
    ) {
      data {
        id
        attributes {
          country
          bic
          name
        }
      }
    }
  }
`;
